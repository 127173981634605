<template>
    <div style="height: 100%">

        <!--<CForm @submit.prevent="getResultsByConditions()">
            <CRow>

            </CRow>
        </CForm>-->

        <CRow>
            <!--<CCol lg="3">
                <label>Filter:</label><input class="sample1-filter-input">
            </CCol>-->
            <CCol lg="3">
                <CInput
                        type="date"
                        name="action"
                        v-model="conditions.created_date"
                        required
                        @input="getResults"
                />
            </CCol>


            <CCol lg="3">
                <download-excel
                        ref="grid"
                        :fetch="getResults"
                        :fields="fields_for_excel"
                        :stringifyLongNum="true"
                        :escapeCsv="true"
                        :meta="json_meta"
                        class="btn btn-outline-primary"
                        type="scv"
                        :name="exportName"
                >
                    Скачать отчет
                </download-excel>
            </CCol>
        </CRow>

        <c-grid
                ref="grid"
                :data="tData"
                :frozen-col-count="1"
                @changed-value="$refs.grid.invalidate()"
                :theme="userTheme">
            <!-- define checkbox -->
            <c-grid-column
                    field="name"
                    width="20%"
                    min-width="350"
                    caption="Нитка"
            />
            <c-grid-input-column
                    field="9_39"
                    caption="Qч"
                    min-width="150"
                    :header-style="{textAlign: 'center'}"
            />
            <c-grid-input-column
                    field="10_39"
                    caption="Q Накоп"
                    min-width="150"
                    :header-style="{textAlign: 'center'}"
            />
            <c-grid-column
                    field="forecast"
                    caption="00:00 гача"
                    min-width="150"
                    :header-style="{textAlign: 'center'}"
            />
            <c-grid-column
                    field="minus"
                    caption="Фарқи"
                    min-width="150"
                    :header-style="{textAlign: 'center'}"
            />
        </c-grid>
    </div>
</template>

<script>
  import axios from 'axios';


  import * as cGridAll from 'vue-cheetah-grid';
  import Vue from 'vue';

  const materialDesignTheme = cGridAll.cheetahGrid.themes.MATERIAL_DESIGN;
  Vue.use(materialDesignTheme)


  import JsonExcel from "vue-json-excel";

  Vue.component("downloadExcel", JsonExcel);


  let tData = [];

  const formatYmd = (date) => {
    return date.toISOString().slice(0, 10)
  };

  let tzoffset = (new Date()).getTimezoneOffset() * 60000;
  let today = formatYmd(new Date(Date.now() - tzoffset));

  let obj = {},
    temp_tdata_yesterday = [];

  export default {
    name: 'Results_22',
    data: function () {
      return {
        exportName: "filename.xlsx",
        tData,
        temp_tdata: [],
        conditions:
          {
            created_date: today,
            time_id: ''
          },
        dataTypes: [],
        timeDatas: [],
        apiDatas: [],
        fields: [
          {
            field: "line_order",
            hidden: true,
            sortable: true,
            type: 'number',
          },
          {
            field: "name",
            label: "Нитка",
          }
        ],
        fields_for_excel: {
            "Нитка": "name",
            "Qч": "9_39",
            "Q_Накоп": "10_39",
            "00:00_гача": "forecast",
            "Фарқи":"minus"
          },
        json_meta: [
          [{
            " key ": " charset ",
            " value ": " utf- 8 "
          }]
        ],
        userTheme: materialDesignTheme.extends({
          defaultBgColor({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];

            if (record !== undefined && record['minus'] !== undefined) {
              if (parseFloat(record['minus']) < -10000 || parseFloat(record['minus']) > 10000) {
                return "#c15353"
              }
            }

            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#3c4b64"
            }
            if (record !== undefined && record.name === "Итого") {
              return '#DEF';
            } else {
              if (col !== 0 && col % 5 === 0) {
                return "#f1f1f1"
              }
            }
          },
          color({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }

            if (record !== undefined && record['minus'] !== undefined) {
              if (parseFloat(record['minus']) < -10000 || parseFloat(record['minus']) > 10000) {
                return "#ffffff"
              }
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#fff"
            }
          },
          borderColor({col, row, grid}) {
            const record = tData[row - grid.frozenRowCount];
            if (row < grid.frozenRowCount) {
              return null;
            }
            if (record !== undefined && record.is_region !== undefined) {
              return "#3c4b64"
            }
            return "#ccc"
          }
        }),
        form: {
          action: 'create_data',
          operator_id: "13",
          datas: [],
          time_id: "",
          line_id: "",
          created_date: "",
        },
      }
    },
    methods: {
        getResults: function () {

            let startDate = this.conditions.created_date;
            let endDate = new Date(startDate);
            let aDayBefore = new Date(startDate);

            endDate.setDate(endDate.getDate(startDate) + 1);
            endDate = formatYmd(endDate);

            aDayBefore.setDate(aDayBefore.getDate(startDate) - 1);
            aDayBefore = formatYmd(aDayBefore);

            temp_tdata_yesterday = JSON.parse(JSON.stringify([]))

            axios
              .get(`${axios.defaults.baseURL}?action=get_all_lines_w_region`)
              .then((response) => {
                this.temp_tdata = JSON.parse(JSON.stringify([]))
                this.temp_tdata = JSON.parse(JSON.stringify(response.data))
                // this.temp_tdata = response.data;

                // Закидываем все нитки в тДата
                this.tData = JSON.parse(JSON.stringify([]));
                this.tData = JSON.parse(JSON.stringify(Object.values(this.temp_tdata)));
                console.log(startDate);
                axios
                  .get(`${axios.defaults.baseURL}?action=get_results_by_date_time_id&created_date=${startDate}&time_id=39`)
                  .then((response) => {
                    let temp_count_array = {};
                    Object.values(response.data).forEach((el, index) => {

                      // получаем "адрес" целевой ячейки внутри строки
                      let a_c;
                      // получаем значение для целевой ячейки
                      let b;
                      // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                      let f = {};

                      let r_id;
                      // получаем "адрес" целевой ячейки внутри строки
                      a_c = el.target;
                      // получаем значение для целевой ячейки
                      b = el.datavalue;
                      // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                      f[a_c] = b;

                      this.temp_tdata[el.line_id + "_id"] = Object.assign({}, this.temp_tdata[el.line_id + "_id"], f);


                      if (this.temp_tdata[el.line_id + "_id"] !== undefined && !isNaN(this.temp_tdata[el.line_id + "_id"]["10_39"]) && !isNaN(this.temp_tdata[el.line_id + "_id"]["9_39"])) {

                        this.temp_tdata[el.line_id + "_id"].minus = 0;

                        obj['forecast'] = (parseFloat(this.temp_tdata[el.line_id + "_id"]["9_39"]) * el.rate + parseFloat(this.temp_tdata[el.line_id + "_id"]["10_39"])).toFixed();

                        if (!isNaN(parseFloat(obj['forecast']))) {
                            this.temp_tdata[el.line_id + "_id"] = Object.assign({}, this.temp_tdata[el.line_id + "_id"], obj);
                            temp_count_array[el.region_id] = temp_count_array[el.region_id] === undefined ? parseFloat(obj['forecast']) : parseFloat(temp_count_array[el.region_id]) + parseFloat(obj['forecast']);
                        }

                        this.temp_tdata[el.region_id + "_id_r_count"] = Object.assign({}, this.temp_tdata[el.region_id + "_id_r_count"], {forecast: temp_count_array[el.region_id]});

                      }
                    })

                    this.temp_tdata.total_r_count['forecast'] = Object.values(temp_count_array).reduce((a,b)=>a+b,0);



                    tData = JSON.parse(JSON.stringify(Object.values(this.temp_tdata)));

                    let day_before_data;

                    axios
                      .get(`${axios.defaults.baseURL}?action=get_results_by_date_time_id&created_date=${aDayBefore}&time_id=39`)
                      .then((response_day_before) => {

                        day_before_data = JSON.parse(JSON.stringify(Object.values(response_day_before.data)))
                        day_before_data.forEach((el_b, index) => {


                          // получаем "адрес" целевой ячейки внутри строки
                          let a_c_yesterday;
                          // получаем значение для целевой ячейки
                          let b_yesterday;
                          // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                          let f_yesterday = {};

                          // получаем "адрес" целевой ячейки внутри строки
                          a_c_yesterday = el_b.target;
                          // получаем значение для целевой ячейки
                          b_yesterday = el_b.datavalue;
                          // создаём и заполняем объект {адрес_ячейки : значение_ячейки}
                          f_yesterday[a_c_yesterday] = b_yesterday;

                          let l_id = el_b['line_id'] + '_id';

                          temp_tdata_yesterday[l_id] = Object.assign({}, temp_tdata_yesterday[l_id], f_yesterday);

                          if (temp_tdata_yesterday[l_id] !== undefined && temp_tdata_yesterday[l_id]['10_39'] !== undefined) {
                            obj['forecast_yesterday'] = (parseFloat(temp_tdata_yesterday[l_id]["9_39"]) * el_b.rate + parseFloat(temp_tdata_yesterday[l_id]["10_39"])).toFixed();
                            if (this.temp_tdata[l_id]['forecast'] !== undefined) {
                              let a = parseFloat(this.temp_tdata[l_id]['forecast']) - parseFloat(obj['forecast_yesterday'])
                              this.temp_tdata[l_id]['minus'] = a;
                            }
                          }
                        })

                        tData = JSON.parse(JSON.stringify(Object.values(this.temp_tdata)));
                        this.tData = JSON.parse(JSON.stringify(tData));
                        this.$refs.grid.invalidate()
                      })
                  })
              })

            this.exportName = startDate + "_22.xlsx"

            return this.tData;
        },
        createData(in_data) {

            let bodyFormData = new FormData();

            bodyFormData.append('action', "create_data_from_table");
            bodyFormData.append('operator_id', this.form.operator_id);
            bodyFormData.append('line_id', in_data.record.id);
            bodyFormData.append('time_id', in_data.field.split("_")[1]);
            bodyFormData.append('data_type_id', in_data.field.split("_")[0]);
            bodyFormData.append('s_value', in_data.value);
            bodyFormData.append('date', this.conditions.created_date);

            axios({
                method: "post",
                url: `${axios.defaults.baseURL}`,
                data: bodyFormData
            })
            .then((r) => {
                console.log(r)
            })
            .catch((error) => {
                console.error(error)
            }).finally(() => {});
        },
    },
    mounted() {
      this.getResults();
        this.$refs.grid.$on("changed-value",(e)=> {
            this.createData(e)
        })
    },

    components: {
      ...cGridAll
    }
  }
</script>
<style>
    .container-fluid {
        height: 100%;
    }
</style>